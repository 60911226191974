import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'They Say News', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Data Analytics and Science in news and social platforms', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hey there! We are',
  name: 'TheySay News',
  subtitle: 'We do data science on social media for fun and trends analysis',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'logo.png',
  paragraphOne:
    'We are team of data science and analytics enthusiasts with strong social and privacy principles.',
  paragraphTwo:
    "Our goal is to analyse trends in social media with `Influencers` phenomenon and the way it reflects in classical news resources and thus in people' minds.",
  paragraphThree:
    'Of course thre is no single truth in anything, but we aim at least to show what people say about particular things happening around.',
  // resume: 'mailto:team@theysay.news',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'cto.jpeg',
    title: 'CTO',
    info: 'Oleksii Zhyrenkov',
    info2:
      'Data engineer, business analyst and social activist. Have been working in various data science projects including non-profits',
    url: 'https://www.facebook.com/ozhyrenkov',
  },
  {
    id: nanoid(),
    img: 'privacy.jpeg',
    title: 'Privacy Officer',
    info: 'Oleksandr Voloshyn',
    info2:
      'Legal expert in privacy and human rights. Major expirience includes anti-corruption and anti-bribery cases. Loves cats.',
    url: 'https://www.facebook.com/oleksandr.voloshyn93',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'team@theysay.news',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
